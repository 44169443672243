'use client'

import { cn } from '~/core/ui/utils'

type FCC<Props = Record<string, unknown>> = React.FC<
  React.PropsWithChildren<Props>
>

const TypographyH1: FCC<{ className?: string }> = ({ className, children }) => {
  return (
    <h1 className={cn('text-4xl font-extrabold', className)}>{children}</h1>
  )
}

const TypographyH2: FCC<{ className?: string }> = ({ className, children }) => {
  return <h2 className={cn('text-3xl font-semibold', className)}>{children}</h2>
}

const TypographyH3: FCC<{ className?: string }> = ({ className, children }) => {
  return <h3 className={cn('text-2xl font-semibold', className)}>{children}</h3>
}

const TypographyH4: FCC<{ className?: string }> = ({ className, children }) => {
  return <h4 className={cn('text-xl font-semibold', className)}>{children}</h4>
}

const TypographyH5: FCC<{ className?: string }> = ({ className, children }) => {
  return <h4 className={cn('text-lg font-semibold', className)}>{children}</h4>
}

export { TypographyH1, TypographyH2, TypographyH3, TypographyH4, TypographyH5 }
